import axios from 'axios';

const { REACT_APP_BASE_URL: baseUrl } = process.env;

class NewComplaintSubscriptionService {
  constructor() {
    this.url = `${baseUrl}/complaints/`;
  }

  subscribeComplaint({ complaint_id, subscriberEmail }) {
    return axios.put(`${this.url}subscribe/${complaint_id}`, {
      email: subscriberEmail,
    });
  }

  unSubscribeComplaint({ complaint_id, subscriberEmail }) {
    return axios.put(`${this.url}unsubscribe/${complaint_id}`, {
      email: subscriberEmail,
    });
  }
}

export default new NewComplaintSubscriptionService();
