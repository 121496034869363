import { GET_COMPLAINTS, GET_ONE_COMPLAINT, SET_FILTERED } from '../actions/types';

const initialState = {
  complaints: [],
  filtered: [],
  complaint: null,
  error: {},
  loading: true,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_COMPLAINTS:
      return {
        ...state,
        complaints: payload,
        filtered: payload,
        loading: false,
      };
    case GET_ONE_COMPLAINT:
      return {
        ...state,
        complaint: payload,
        loading: false,
      };
    case SET_FILTERED:
      return {
        ...state,
        filtered: payload,
      };
    default:
      return state;
  }
}
