import React, { memo } from 'react';
import styled from 'styled-components';

const SreenWrapper = styled.div`
  background: url(${(props) => props.img});
  background-size: cover;
  background-position: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: auto;
`;

const FinishScreen = () => (
  <SreenWrapper img="https://cdn.dribbble.com/users/4045758/screenshots/9521984/media/b86a148b47f9161dade89d29dcf556b8.gif" />
);

export default memo(FinishScreen);
