import axios from 'axios';
import { GET_COMPLAINTS, GET_ONE_COMPLAINT } from './types';

const { REACT_APP_BASE_URL: baseUrl, REACT_APP_URL_ID: urlId } = process.env;

export const getAllComplaints = (type) => async (dispatch) => {
  const options = type ? `?status=${type}` : '';
  try {
    const res = (
      await axios.get(`${baseUrl}/complaints/url_id/${urlId}${options}`)
    ).data;
    dispatch({
      type: GET_COMPLAINTS,
      payload: res,
    });
  } catch (error) {
    console.error(error.message);
  }
};

export const getComplaintById = (id) => async (dispatch) => {
  try {
    const res = (await axios.get(`${baseUrl}/complaints/${id}`)).data;
    const images = JSON.parse(res.images);
    dispatch({
      type: GET_ONE_COMPLAINT,
      payload: { ...res, images },
    });
  } catch (error) {
    console.error(error.message);
  }
};

export const createComplaint = (data) => async (dispatch) => {
  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    data['url_id'] = urlId;
    await axios.post(`${baseUrl}/complaints`, data, config);
    dispatch(getAllComplaints());
  } catch (error) {
    console.error(error.message);
  }
};

// export const subscribeComplaint = ({
//   complaint_id,
//   subscriberEmail,
// }) => async () => {
//   try {
//     const config = { headers: { 'Content-Type': 'application/json' } };
//     const response = await NewComplaintSubscriptionService.subscribeComplaint(
//       { complaint_id, subscriberEmail },
//       config,
//     );
//     console.log('Subscribe Complaint Response', response);
//   } catch (error) {
//     console.error(error.message);
//   }
// };

// export const unSubscribeComplaint = ({
//   complaint_id,
//   subscriberEmail,
// }) => async () => {
//   try {
//     const config = { headers: { 'Content-Type': 'application/json' } };
//     const response = await NewComplaintSubscriptionService.ubSubscribeComplaint(
//       { complaint_id, subscriberEmail },
//       config,
//     );
//     console.log('Unsubscribe Complaint Response', response);
//   } catch (error) {
//     console.error(error.message);
//   }
// };
