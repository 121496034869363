import styled from 'styled-components';

export const CardWrapper = styled.div`
  overflow-y: scroll;
  padding: 8px;
}
  &::-webkit-scrollbar {
      -webkit-appearance: none;
      width:6px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,0.3);
      border-radius: 10px;
  }
  .title{
    margin-bottom: 20px;
    display: flex;
    align-items:center;
     h1 {
       font-family: Roboto;
       font-weight: normal;
       font-size: 1.5rem;
       letter-spacing: 0.0025em;
       color: ${(props) => props.theme.main};
    }
    p {
      margin: 6px 0;
      font-family: Roboto;
      font-weight: 500;
      font-size: 1.3rem;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.5);
    }
}
`;

export const CardStyles = styled.div`
  position: relative;
  height: 85%;
  background: #fff;
  box-shadow: var(--card-shadow);
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-rows: max-content max-content 1fr max-content;
  grid-gap: 20px;
`;

export const IconWrapper = styled.div`
  width: 64px;
  height: 64px;
  background: #fff;
  margin-right: 14px;
  background: url('${(props) => props.logo}');
  background-size: contain;
  padding: 10px;
`;
