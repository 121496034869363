import React, { memo } from 'react';
import { CardStyles, CardWrapper } from './card.styles';

const Card = ({ children, title, sub }) => (
  <CardWrapper>
    <div className="title">
      <div className="title-wrapper">
        <h1>{title}</h1>
        <p>{sub}</p>
      </div>
    </div>
    <CardStyles>{children}</CardStyles>
  </CardWrapper>
);

export default memo(Card);
