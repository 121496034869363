export const GET_COMPLAINTS = 'GET_COMPLAINTS';
export const GET_ONE_COMPLAINT = 'GET_ONE_COMPLAINT';

export const SET_LOADING = 'SET_LOADING';
export const SET_FILTERED = 'SET_FILTERED';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CITIZEN_SETTING = 'GET_CITIZEN_SETTING';

export const DISABLE_FLASH_MESSAGE = 'DISABLE_FLASH_MESSAGE';
export const ENABLE_FLASH_MESSAGE = 'ENABLE_FLASH_MESSAGE';
