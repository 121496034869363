import React, { memo } from 'react';
import Amplify, { Storage } from 'aws-amplify';
import { nanoid } from 'nanoid';
import awsconfig from '../../services/aws-exports';

Amplify.configure(awsconfig);
Storage.configure({
  customPrefix: {
    public: '',
  },
});

const S3ImageUpload = ({ handleImages }) => {
  const onChange = (files) => {
    const uploadedFiles = [];
    Object.values(files)
      .filter((file) => (typeof file === 'object' && ['image/png', 'image/jpeg', 'image/gif'].includes(file.type)))
      .forEach(async (file) => {
        const { key } = await Storage.put(`media/pictures/${nanoid()}`, file, { contentType: file.type, acl: 'public-read' });
        if (key) {
          uploadedFiles.push(`https://${process.env.REACT_APP_BUCKET_NAME}.s3.ca-central-1.amazonaws.com/${key}`);
        }
      });

    handleImages(uploadedFiles);
  };

  return (
    <input
      type="file"
      accept="image/png, image/jpeg, image/gif"
      multiple
      onChange={(e) => onChange(e.target.files)}
    />
  );
};

export default memo(S3ImageUpload);
