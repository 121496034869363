import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(() => ({
  stepper: {
    padding: 0,
  },
}));

const CustomStepper = ({ steps, activeStep }) => {
  const classes = useStyles();

  return (
    <Stepper
      activeStep={activeStep}
      className={classes.stepper}
      orientation="vertical"
    >
      {steps.map((label) => {
        const stepProps = {};
        const labelProps = {};
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default memo(CustomStepper);
