import { GET_CATEGORIES, GET_CITIZEN_SETTING } from '../actions/types';

const initialState = {
  title: '',
  sub_title: '',
  theme: '',
  categories: [],
  error: {},
  loading: true,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
        loading: false,
      };
    case GET_CITIZEN_SETTING:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    default:
      return state;
  }
}
