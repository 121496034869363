import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Axios from 'axios';
import ImageCell from '../misc/ImageCell';
import { createCategories } from '../../utils/utils';
import S3ImageUpload from '../misc/S3ImageUpload';

const { REACT_APP_BASE_URL: baseUrl, REACT_APP_URL_ID: urlId } = process.env;

const IssueDetailsStyles = styled.div`
  .row {
    margin-bottom: 20px;
  }
`;

const Label = styled.div`
  margin: 0;
  margin-bottom: 10px;
`;

const IssueDetails = ({ complaint, setComplaint }) => {
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState('');
  const handleImages = (images) =>
    setComplaint((prev) => ({ ...prev, images }));

  useEffect(() => {
    (async () => {
      let cat = (await Axios.get(`${baseUrl}/categories/url_id/${urlId}`)).data;
      cat = cat?.map(createCategories);
      setCategories(cat);
    })();
  }, []);

  return (
    <IssueDetailsStyles>
      <div className="row">
        <Label htmlFor="">Type of Complaint</Label>
        <Autocomplete
          id="grouped-demo"
          fullWidth
          inputValue={type}
          value={complaint.type}
          onInputChange={(e, v) => setType(v)}
          onChange={(e, v) => setComplaint({ ...complaint, type: v })}
          options={categories?.sort((a, b) => a < b)}
          getOptionSelected={(option, v) => option.id === v.id}
          renderOption={(option) => (
            <ImageCell value={`${option.type}`} text image={option.icon} />
          )}
          getOptionLabel={(option) => option.type || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search or select a type"
              variant="outlined"
            />
          )}
        />
      </div>
      <div className="row">
        <Label htmlFor="">Description</Label>
        <TextField
          className="text-field"
          required
          placeholder="Describe the issue"
          id="outlined-basic"
          variant="outlined"
          fullWidth
          multiline
          onChange={(e) =>
            setComplaint({ ...complaint, [e.target.name]: e.target.value })
          }
          rows={8}
          name="comment"
          value={complaint?.comment}
        />
      </div>
      <div className="row">
        <Label htmlFor="">Upload Images</Label>
        <S3ImageUpload handleImages={handleImages} />
      </div>
    </IssueDetailsStyles>
  );
};

export default memo(IssueDetails);
