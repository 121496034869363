import React, { memo, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Box, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {
  Done,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Restore,
} from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import IssueDetails from '../issue-details/IssueDetails';
import CustomStepper from '../misc/CustomStepper';
import UserDetails from '../user-details/UserDetails';
import { ButtonWrapper } from './stepper-container.styles';
import { getAllComplaints } from '../../actions/compaints.action';
import { COMPLAINT } from '../../utils/constants';
import Map from '../map/Map';
import SearchBox from '../map/SearchBox';
import { ENABLE_FLASH_MESSAGE } from '../../actions/types';
import FinishScreen from './FinishScreen';

const { REACT_APP_BASE_URL: baseUrl, REACT_APP_URL_ID: urlId } = process.env;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorMessage: {
    color: 'red',
  },
  successMessage: {
    color: 'green',
  },
}));

const getSteps = () => [
  'Add location of issue',
  'Enter details',
  'Fill your contact details',
];

const StepperContainer = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const steps = getSteps();

  const handleNext = () => {
    const { address, location, type, comment } = props.complaint;

    const message =
      !(address && location) && activeStep === 0
        ? 'Select the location on the map'
        : !(type && comment) && activeStep === 1
        ? 'Select the type and provide description'
        : null;

    if (message) {
      dispatch({
        type: ENABLE_FLASH_MESSAGE,
        payload: {
          message,
          type: 'warning',
        },
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleFinish = () => {
    const { first_name, last_name, type } = props.complaint;

    const message =
      !(first_name && last_name) && activeStep === 2
        ? 'Fill the required fields'
        : null;

    if (message) {
      dispatch({
        type: ENABLE_FLASH_MESSAGE,
        payload: {
          message,
          type: 'warning',
        },
      });
    } else {
      submitComplaint({ ...props?.complaint, type: type.id });
      // dispatch(createComplaint({ ...props?.complaint, type: type.id }));
      handleNext();
      props.setComplaint(COMPLAINT);
    }
  };

  const submitComplaint = async (data) => {
    try {
      const config = { headers: { 'Content-Type': 'application/json' } };
      data['url_id'] = urlId;
      const response = await axios.post(`${baseUrl}/complaints`, data, config);
      setSuccessMessage('Service request has been submmited successfully.');
      window.ChurnZero.push(['trackEvent', 'CE-Complaint-Created']);
      dispatch(getAllComplaints());
    } catch (error) {
      setIsError(true);
      setErrorMessage(
        'Error submitting request. Please make sure the city you enter is as per your domain.',
      );
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    props.setComplaint(COMPLAINT);
    setIsError(false);
    setErrorMessage('');
    setSuccessMessage('');
  };

  return (
    <>
      <CustomStepper steps={steps} activeStep={activeStep} />
      {activeStep === 0 ? (
        <Box>
          <SearchBox {...props} />
          <Map
            {...props}
            googleMapURL={process.env.REACT_APP_GOOGLE_MAP_API}
            loadingElement={<div style={{ height: '400px' }} />}
            containerElement={<div style={{ height: '400px' }} />}
            mapElement={<div style={{ height: '400px' }} />}
          />
        </Box>
      ) : null}
      {activeStep === 1 ? (
        <Box>
          <IssueDetails {...props} />
        </Box>
      ) : null}
      {activeStep === 2 ? (
        <Box>
          <UserDetails {...props} />
        </Box>
      ) : null}
      {activeStep !== 0 &&
      activeStep !== 1 &&
      activeStep !== 2 &&
      errorMessage === '' &&
      successMessage === '' ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
          {/* <FinishScreen /> */}
        </Box>
      ) : null}

      {activeStep === steps.length ? (
        <Box>
          {isError ? (
            errorMessage !== '' ? (
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            ) : null
          ) : null}

          {!isError ? (
            successMessage !== '' ? (
              <Alert variant="filled" severity="success">
                {successMessage}
              </Alert>
            ) : null
          ) : null}
          <Button
            onClick={handleReset}
            className={classes.button}
            size="large"
            startIcon={<Restore />}
          >
            New
          </Button>
        </Box>
      ) : (
        <ButtonWrapper>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            startIcon={<KeyboardArrowLeft />}
            size="large"
            className={classes.button}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleFinish}
              endIcon={<Done />}
              size="large"
              className={classes.button}
            >
              Finish
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              endIcon={<KeyboardArrowRight />}
              size="large"
              className={classes.button}
            >
              Next
            </Button>
          )}
        </ButtonWrapper>
      )}
    </>
  );
};

export default memo(StepperContainer);
