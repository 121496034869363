import styled from 'styled-components';

export const IssueListStyles = styled.div`
  width: 100%;
  height: calc(100vh - 174px);
  background: rgb(247, 251, 253);
  border-radius: 12px;
  padding: 0 20px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: background 200ms ease-in-out 0s, border 200ms ease-in-out 0s;
  position: relative;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  header {
    position: sticky;
    top: 0;
    background: #f7fbfd;
    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        height: 48px;
      }
      h3 {
        font-family: Roboto;
        font-style: normal;
        font-size: 15px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 20px;
        padding-top: 20px;
        text-transform: capitalize;
      }
    }
    .search {
      width: 100%;
      margin-bottom: 20px;
      height: 50px;
      background: #fff;
      border-radius: 8px;
      box-shadow: rgba(0, 74, 140, 0.14) 0px 2px 6px;
      overflow: hidden;
      display: flex;
      align-items: center;
      svg {
        padding: 12px;
        margin-left: 4px;
        box-sizing: content-box;
      }
      input {
        background: rgb(255, 255, 255);
        border: 0px;
        outline: 0px;
        height: 100%;
        width: 100%;
        color: rgb(66, 66, 66);
        font-size: 14px;
      }
    }
  }
`;

export const ComplaintStyles = styled.div`
    margin: auto auto 20px;
    background: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    overflow: hidden;
    user-select: none;
    transition: background 200ms ease-in-out 0s, box-shadow 200ms ease-in-out 0s;
    width: 100%;
    padding: 16px;
    padding-top:10px;
    box-sizing: border-box;
    color: rgb(66, 66, 66);
    border-bottom: 3px solid ${(props) =>
      props.theme[props.type][props.status]};
    cursor: pointer;
    .content {
        display:grid;
        grid-template-columns: max-content max-content 1fr;
        grid-gap: 3px 0px;
        p {
            margin-left: 10px;
        }
        span {
            font-weight: 500;
            font-size: 13px;
        }
        img {
            width: 35px;
            height: 35px;
            margin-right: 10px;
            grid-row:span 2;
        }
    }
    .supText,text,subText{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 9px;
        display: flex;
    }
    .supText{
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .text{
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

`;

export const Pill = styled.span`
  display: block;
  padding: 0 10px;
  width: ${(props) => props.width || '100%'};
  line-height: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #212121;
  background: ${(props) => props.theme[props.type][props.status]};
`;
