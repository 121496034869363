import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  createMuiTheme,
  ThemeProvider as MThemeProvider,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from './components/nav/NavBar';

import { lightTheme } from './components/Theme';
import Routes from './components/routes/Routes';
import { getCitizenAppSetting } from './actions/app.action';
import SnackBar from './components/misc/Snackbar';

const App = () => {
  const dispatch = useDispatch();

  // GLOBAL STATE
  const {
    app: { theme },
  } = useSelector((state) => ({
    app: state.app,
  }));

  useEffect(() => {
    dispatch(getCitizenAppSetting());
  }, []);

  const mtheme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: theme || '#eee',
          },
        },
      }),
    [theme],
  );

  return (
    <MThemeProvider theme={mtheme}>
      <ThemeProvider theme={{ ...lightTheme, main: theme }}>
        <div className="App">
          <NavBar />
          <Routes />
          <SnackBar />
        </div>
      </ThemeProvider>
    </MThemeProvider>
  );
};

export default App;
