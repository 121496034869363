import { ENABLE_FLASH_MESSAGE, DISABLE_FLASH_MESSAGE } from '../actions/types';

const initialState = {
  show: false,
  message: '',
  type: '',
};

const flashMessagesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ENABLE_FLASH_MESSAGE:
      return {
        ...state,
        show: true,
        message: payload.message,
        type: payload.type,
      };
    case DISABLE_FLASH_MESSAGE:
      return {
        ...state,
        show: false,
        message: '',
        type: '',
      };
    default:
      return state;
  }
};
export default flashMessagesReducer;
