import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import Main from '../main/Main';

const Routes = () => (
  <>
    <Switch>
      <Route exact path="/" component={Main} />
    </Switch>
  </>
);
export default memo(Routes);
