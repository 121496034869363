import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyCIQ1linyOfkweo0DW7B_M3xpx1tb1aO-g');
Geocode.enableDebug();

export const getColor = (str, s, l) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str?.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export const createCategories = (cat) => {
  const firstLetter = cat?.type.charAt(0).toUpperCase();
  return {
    ...cat,
    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
    type: cat.type.split(' ').map((s) => `${s[0].toUpperCase()}${s.slice(1)}`).join(' '),
  };
};

export const getLatLng = async (address) => {
  const res = await Geocode.fromAddress(address);
  const loc = await res.results[0].geometry.location;
  return loc;
};

export const getAddress = async ({ lat, lng }) => {
  const res = await Geocode.fromLatLng(lat, lng);
  const address = await res.results[0].formatted_address;
  return address;
};
