import React, { memo } from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoIcon } from '../../assets/nav-logo.svg';
import { ReactComponent as LogoText } from '../../assets/logo-text.svg';

const NavBarStyles = styled.div`
  width: 100%;
  height: 70px;
  background: ${(props) => props.theme.main};
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .menu-items {
    margin-right: 10px;
  }
  .brand {
    height: 70px;
    display: inline-flex;
    align-items: center;
  }
`;

const NavBar = () => {
  return (
    <NavBarStyles>
      <div className="brand">
        <LogoIcon width="44px" height="44px" style={{ margin: '0 10px' }} />
        <LogoText width="100px" height="44px" />
      </div>
    </NavBarStyles>
  );
};

export default memo(NavBar);
