export const lightTheme = {
  theme: 'light',
  color: {
    background: '#F7FBFD',
    appbar: '#0067AB',
    primary: '#0067AB',
    card: '#fff',
    overlay: 'rgba(0, 61, 123, 0.2)',
    text: '#424242',
    contrast: '#000',
    border: 'rgba(0, 0, 0, 0.4)',
  },

  card: {
    shadow: 'rgba(0, 74, 140, 0.14) 0px 2px 6px',
  },

  btn: {
    color: 'rgba(0,103,171,.08)',
    hover: 'rgba(0,103,171,.15)',
    text: 'rgba(0,103,171,.7)',
  },

  divider: 'rgb(0 0 0 / 12%)',

  status: {
    new: '#f08c88',
    'in-progress': '#fcd94b',
    feedback: '#ae93ea',
    closed: '#6ed8c2',
    rejected: '#bdbdbd',
    acknowledged: '#ae93ea',
  },
};
