import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import NewComplaintSubscriptionService from '../../services/complaint-subscription.service';

import { getComplaintById } from '../../actions/compaints.action';
import { GET_ONE_COMPLAINT } from '../../actions/types';
import { setLoading } from '../../actions/loading.action';
import ImageCell from '../misc/ImageCell';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogStyles = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  grid-gap: 20px 40px;
  padding-bottom: 20px;
  .date {
    font-size: 14px;
    font-weight: bold;
  }
  p {
    color: #9e9e9e;
  }
`;

const Pill = styled.span`
  display: block;
  padding: 0 20px;
  width: ${(props) => props.width || '100%'};
  line-height: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #212121;
  background: ${(props) => props.theme[props.type][props.status]};
`;

const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const getImage = (complaint) => {
  if (complaint?.status !== 'New') {
    if (complaint?.images.length > 0) {
      return (
        <ImageWrapper>
          {complaint?.images.map((image) => (
            <img key={image} src={image} alt="" />
          ))}
        </ImageWrapper>
      );
    }
    return <p>N/A</p>;
  }
  return <p>Review Pending</p>;
};

const GridCell = ({ label, value, styles }) => (
  <>
    <span style={styles}>{label}:</span>
    {value}
  </>
);

const IssuePopup = ({ id, setPopUp }) => {
  const dispatch = useDispatch();
  const {
    complaints: { complaint, loading },
  } = useSelector((state) => ({
    complaints: state.complaints,
  }));
  const [subscriberEmail, setSubscriberEmail] = useState('');
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false);
  const [
    showSubscriptionSuccessMessage,
    setShowSubscriptionSuccessMessage,
  ] = useState(false);

  useEffect(() => {
    if (id != null) {
      dispatch(getComplaintById(id));
      setSubscriberEmail('');
      setShowSubscriptionForm(false);
      setShowSubscriptionSuccessMessage(false);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id != null) {
      dispatch(setLoading(true));
    }
  }, [loading]);

  const closePopUp = () => {
    setPopUp(null);
  };

  const handleShowSubscriptionForm = () => {
    setShowSubscriptionForm(true);
  };

  const handleSubscribeComplaint = async () => {
    if (id && subscriberEmail) {
      try {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const response = await NewComplaintSubscriptionService.subscribeComplaint(
          { complaint_id: id, subscriberEmail },
          config,
        );
        if (response.status === 200) {
          window.ChurnZero.push(['trackEvent', 'CE-Complaint-Subscribed']);
          setShowSubscriptionForm(false);
          setSubscriberEmail('');
          setShowSubscriptionSuccessMessage(true);
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  return (
    <Dialog
      open={id !== null}
      onClose={closePopUp}
      onExited={() =>
        dispatch({
          type: GET_ONE_COMPLAINT,
          payload: null,
        })
      }
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
      keepMounted
      maxWidth="xs"
    >
      <DialogTitle id="form-dialog-title">
        <ImageCell
          image={complaint?.category.icon}
          text
          value={`Complaint Details | ${complaint?.category.type}`}
        />
      </DialogTitle>
      <DialogContent>
        <DialogStyles>
          <div className="date">
            {moment(complaint?.date).format('DD/MM/YYYY, h:mm a')}
          </div>
          <br />
          <GridCell label="Description" value={<p>{complaint?.comment}</p>} />
          <GridCell
            styles={{ alignSelf: 'start' }}
            label="Address"
            value={<p>{complaint?.address}</p>}
          />
          <GridCell
            label="Status"
            value={
              <Pill
                width="max-content"
                status={complaint?.status?.split(' ').join('-').toLowerCase()}
                type="status"
              >
                {complaint?.status}
              </Pill>
            }
          />

          <GridCell
            styles={{ alignSelf: 'start' }}
            label="Images"
            value={getImage(complaint)}
          />
        </DialogStyles>
      </DialogContent>
      {showSubscriptionSuccessMessage ? (
        <DialogContent>
          <Typography variant="body1" align="center" gutterBottom>
            You've successfully subscribed to this complaint.
          </Typography>
        </DialogContent>
      ) : showSubscriptionForm ? (
        <DialogActions>
          <TextField
            id="outlined-basic"
            label="Enter Email Address"
            variant="outlined"
            size="small"
            value={subscriberEmail}
            onChange={(e) => {
              setSubscriberEmail(e.target.value);
            }}
          />
          <Button color="primary" onClick={handleSubscribeComplaint}>
            subscribe
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button color="primary" onClick={handleShowSubscriptionForm}>
            Want to receive updates?
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default memo(IssuePopup);
