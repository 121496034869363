import Amplify from 'aws-amplify';

export default Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_POOL_ID, // OPTIONAL -  Amazon service region
    region: process.env.REACT_APP_BUCKET_REGION, // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_BUCKET_NAME, // REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_BUCKET_REGION, // OPTIONAL -  Amazon service region
    },
  },
});
