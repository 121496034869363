import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autorenew, Search } from '@material-ui/icons';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { getAllComplaints } from '../../actions/compaints.action';
import { setLoading } from '../../actions/loading.action';
import IssuePopup from './IssuePopup';
import { ComplaintStyles, IssueListStyles, Pill } from './issue-list.styles';
import { SET_FILTERED } from '../../actions/types';

const IssueList = () => {
  const dispatch = useDispatch();
  const {
    complaints: { complaints, filtered, loading },
  } = useSelector((state) => ({
    complaints: state.complaints,
  }));

  const [seleceted, setSelected] = useState(null);
  const [search, setSearch] = useState('');

  const [listType, setListType] = useState('Open');

  useEffect(() => {
    dispatch(getAllComplaints(listType));
  }, [listType]);

  useEffect(() => {
    const payload = complaints?.filter((c) => {
      const searchData = `
      ${c.category.type}
      ${c.category.type}
      ${c.status}
      ${c?.user?.street}
      ${c?.user?.zip}`.toLowerCase();
      return searchData.includes(search);
    });
    dispatch({ type: SET_FILTERED, payload });
  }, [search]);

  useEffect(() => {
    dispatch(setLoading(true));
  }, [loading]);

  const onChange = (e) => setSearch(e.target.value);

  return (
    <IssueListStyles>
      <header>
        <div className="top-bar">
          <h3>{`${listType} Issues`}</h3>
          <IconButton
            color="primary"
            component="span"
            onClick={() => setListType(listType === 'Open' ? 'Closed' : 'Open')}
          >
            <Autorenew />
          </IconButton>
        </div>
        <div className="search">
          <Search />
          <input
            type="text"
            placeholder="Search..."
            onChange={onChange}
            value={search}
          />
        </div>
      </header>
      {filtered.slice(0, 10).map((complaint) => (
        <ComplaintStyles
          status={complaint?.status?.split(' ').join('-').toLowerCase()}
          type="status"
          key={complaint?.id}
          onClick={() => setSelected(complaint?.id)}
        >
          <p className="supText">
            <span>{moment(complaint?.date).fromNow()}</span>
            <Pill
              width="max-content"
              status={complaint?.status?.split(' ').join('-').toLowerCase()}
              type="status"
            >
              {complaint?.status}
            </Pill>
          </p>
          <div className="content">
            <img src={complaint?.category.icon} alt="" />
            <span>Location: </span>
            <p className="text" title={complaint?.address}>
              {complaint?.address}
            </p>
            <span>Issue: </span>
            <p className="text">{complaint?.category.type}</p>
          </div>
        </ComplaintStyles>
      ))}
      <IssuePopup id={seleceted} setPopUp={setSelected} />
    </IssueListStyles>
  );
};

export default memo(IssueList);
