export const COMPLAINT_CATEGORIES = [
  { title: 'barking dog / animal noise' },
  { title: 'dead animal' },
  { title: 'dog feces on private property' },
  { title: 'improper animal feeding' },
  { title: 'injured animal' },
  { title: 'mice' },
  { title: 'rats' },
  { title: 'unleashed dog' },
  { title: 'wild animal / rabies inquiry' },
  { title: 'city building maintaince & repairs' },
  { title: 'school building maintaince & repairs' },
  { title: 'catch basin' },
  { title: 'manhole repair' },
  { title: 'pothole' },
  { title: 'sidewalk repair' },
  { title: 'sinkhole' },
  { title: 'street sign issue' },
  { title: 'street / road defect' },
];

export const COMPLAINT = {
  location: {
    lat: 45,
    lng: -37,
  },
  address: '',
  source: 'Citizen App',
  type: '',
  comment: '',
  images: [],
  date: new Date(),
  first_name: '',
  last_name: '',
  email: '',
  street: '',
  city: '',
  state: '',
  zip: '',
};

export const COLORS = [
  '#ef5350',
  '#2196f3',
  '#009688',
  '#8bc34a',
  '#ff9800',
  '#ffc107',
];
