import React, { useState } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { LeakAdd } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { COMPLAINT } from '../../utils/constants';
import Card from './Card';
import StepperContainer from './StepperContainer';
import IssueList from './IssueList';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
}));

const Main = () => {
  const classes = useStyles();

  const [complaint, setComplaint] = useState(COMPLAINT);

  // GLOBAL STATE
  const {
    app: { title, sub_title },
  } = useSelector((state) => ({
    app: state.app,
  }));

  return (
    <Grid container>
      <Grid item sm={12} md={8} className={classes.fullWidth}>
        <Box m={2}>
          <Card title={title} sub={sub_title} Icon={LeakAdd}>
            <StepperContainer
              complaint={complaint}
              setComplaint={setComplaint}
            />
          </Card>
        </Box>
      </Grid>
      <Grid item sm={12} md={4}>
        <Box m={2}>
          <IssueList />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Main;
