import { makeStyles, TextField, Grid } from '@material-ui/core';
import React, { memo } from 'react';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
}));

const Label = styled.div`
  margin: 0;
  margin-bottom: 10px;
`;

const UserDetails = ({ complaint, setComplaint }) => {
  const classes = useStyles();

  const onChange = (e) =>
    setComplaint({ ...complaint, [e.target.name]: e.target.value });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Label htmlFor="">First Name*</Label>
        <TextField
          className="text-field"
          required
          placeholder="Your first name"
          id="outlined-basic"
          variant="outlined"
          onChange={onChange}
          name="first_name"
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Label htmlFor="">Last Name*</Label>
        <TextField
          required
          placeholder="Your last name"
          id="outlined-basic"
          variant="outlined"
          onChange={onChange}
          name="last_name"
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Label htmlFor="">Email Address</Label>
        <TextField
          placeholder="Please provide your email address"
          id="outlined-basic"
          variant="outlined"
          onChange={onChange}
          name="email"
          type="email"
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Label htmlFor="">Street Address</Label>
        <TextField
          required
          placeholder="Please provide your address"
          id="outlined-basic"
          variant="outlined"
          onChange={onChange}
          name="street"
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Label htmlFor="">City</Label>
        <TextField
          required
          placeholder="Please provide your city"
          id="outlined-basic"
          variant="outlined"
          onChange={onChange}
          name="city"
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Label htmlFor="">State</Label>
        <TextField
          required
          placeholder="Please provide your state"
          id="outlined-basic"
          variant="outlined"
          onChange={onChange}
          name="state"
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Label htmlFor="">Postal Code</Label>
        <TextField
          required
          placeholder="Please provide your state"
          id="outlined-basic"
          variant="outlined"
          onChange={onChange}
          name="zip"
          className={classes.formControl}
        />
      </Grid>
    </Grid>
  );
};

export default memo(UserDetails);
