import React, { memo } from 'react';
import styled from 'styled-components';
import { Avatar, Tooltip } from '@material-ui/core';
import { getColor } from '../../utils/utils';

const ImageCellStyles = styled.div`
    display: flex;
    align-items: center;
    margin: ${(props) => (props.text ? '20px 0' : 0)};
`;

const ImageCell = ({
  value, image, text, space, classes = '', props,
}) => {
  const name = value?.toUpperCase()?.split(' ');
  return (
    <ImageCellStyles text={text && space} flip {...props}>
      <Tooltip title={value} arrow>
        <Avatar
          src={image}
          style={{
            background: image ? 'none' : getColor(value, 60, 80),
            color: '#212121',
            marginRight: 10,
          }}
        >
          <span className="name">{`${name[0]?.charAt(0)}${name[1]?.charAt(0)}`}</span>
        </Avatar>
      </Tooltip>
      <span className={classes}>
        {text ? value : null}
      </span>
    </ImageCellStyles>
  );
};

export default memo(ImageCell);
