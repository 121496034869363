import axios from 'axios';
import { GET_CITIZEN_SETTING } from './types';
import { CheckChurnZeroUser } from '../services/churnzero.service';

const { REACT_APP_BASE_URL: baseUrl, REACT_APP_URL_ID: urlId } = process.env;

export const getCitizenAppSetting = () => async (dispatch) => {
  try {
    let res = (await axios.get(`${baseUrl}/citizen-app-setting/${urlId}`)).data;
    const { client_name } = res;
    res = JSON.parse(res?.settings);
    res.client_name = client_name;
    CheckChurnZeroUser({ client_name, urlId });
    dispatch({
      type: GET_CITIZEN_SETTING,
      payload: res,
    });
  } catch (error) {
    console.error(error.message);
  }
};
