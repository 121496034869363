import { combineReducers } from 'redux';

import complaints from './complaints.reducer';
import flash from './flashMessage.reducer';
import app from './app.reducer';

export default combineReducers({
  app,
  complaints,
  flash,
});
