import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { useSelector } from 'react-redux';
import { getAddress } from '../../utils/utils';
import IssuePopup from '../main/IssuePopup';

const Map = withScriptjs(
  withGoogleMap((props) => {
    const { setComplaint, complaint } = props;

    const {
      complaints: { filtered },
    } = useSelector((state) => ({
      complaints: state.complaints,
    }));

    const [marker, setMarker] = useState(null);

    const [loadingLoc, setLoadingLoc] = useState(true);

    useEffect(() => {
      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
          setComplaint({
            ...complaint,
            location: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
          });
          setLoadingLoc(false);
        });
      }
    }, []);

    const mapClick = async (p) => {
      const { latLng } = p;

      setComplaint({
        ...complaint,
        location: {
          lat: latLng.lat(),
          lng: latLng.lng(),
        },
        address: await getAddress({ lat: latLng.lat(), lng: latLng.lng() }),
      });
    };

    const onMarkerClick = (id) => {
      setMarker(id);
    };

    return (
      !loadingLoc && (
        <>
          <GoogleMap
            defaultZoom={11}
            defaultCenter={complaint?.location}
            center={complaint?.location}
            onClick={mapClick}
          >
            <Marker
              position={complaint?.location}
              draggable
              onDragend={onMarkerClick}
            />
            <MarkerClusterer
              // onClick={onMarkerClustererClick}
              averageCenter
              enableRetinaIcons
              gridSize={60}
            >
              {filtered.map((c) => (
                <Marker
                  key={c?.id}
                  position={JSON.parse(c?.location)}
                  icon={{
                    url: c?.category.icon,
                    anchor: new window.google.maps.Point(25, 25),
                    scaledSize: new window.google.maps.Size(50, 50),
                  }}
                  onClick={() => onMarkerClick(c?.id)}
                  clickable
                />
              ))}
            </MarkerClusterer>
            <IssuePopup id={marker} setPopUp={setMarker} />
          </GoogleMap>
        </>
      )
    );
  }),
);

export default Map;
